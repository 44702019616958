@import '../../styles/variables.scss';
@import '../../styles/include-media.scss';

.regionsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.regionBox {
  display: flex;
  gap: 10px;
  padding: 5px 12px;
  max-width: fit-content;
  height: 34px;
  font-size: 14px;
  background-color: #EDEDED;
  align-items: center;
  border-radius: 5px;
}

.errorText {
  color: $error
}

.removeRegionBtn {
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: darkgrey;
  }
}

.addButtonBox {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
}

.addButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $secondary;
}