@import '../../styles/variables.scss';
@import '../../styles/include-media.scss';

.countControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  button {
    display: inline-block;
    font-size: 20px;
    padding-top: 2px;
    height: 30px;
    color: darken($primary, 50%);
  }
}

.btnAmountControl {
  padding: 5px !important;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
}

.countInput {
  margin: 0 5px;
  border-width: 1px;
  border-radius: 3px;
  border-color: #b8b8b8;
  border-style: solid;
  height: 28px;
  line-height: 28px;
  width: 40px;
  text-align: center;
  &:focus {
    border-color: #555;
    outline: none;
  }
}