
@import '../../../styles/variablesNew.scss';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.inputArea {
  height: 100; 
  resize: 'vertical';
  border-radius: 8px;
  padding: 10px 12px;
  width: 100%;
  border: 0;
  background-color: #f8f7f5;
  min-height: 44px;
  outline: 0;
  font-family: 'Noto sans';

  &:focus {
    border: 1px solid $primary;
    background-color: white;
  }

  &::placeholder {
    color: #1C1C1CB8;
  }

  &:disabled::placeholder {
    color: rgba(104, 104, 104, 0.72);
  }

  &:disabled {
    background-color: #fcfbfa;
  }
}

.input {
  border-radius: 8px;
  padding: 10px 12px;
  width: 100%;
  border: 0;
  background-color: #f8f7f5;
  height: 44px;
  outline: 0;
  font-family: 'Noto sans';
  &:focus {
    border: 1px solid $primary;
    background-color: white;
  }

  &::placeholder {
    color: #1C1C1CB8;
  }

  &:disabled::placeholder {
    color: rgba(104, 104, 104, 0.72);
  }

  &:disabled {
    background-color: #fcfbfa;
  }
}

.closeBtn {
  position: absolute;
  display: inline;
  border: none;
  font-size: 20px;
  color: gray;
  background-color: inherit;
  right: 12px;
  cursor: pointer;
  padding: 0;
  display: flex;
}