@import './mixins.scss';

.requestsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.requestCard {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  @include media('<=#{$mobile-step}') {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.requestNum {
  font-weight: 600;
  font-size: 14px;
}

.requestBtn {
  div {
    height: 10px;
    font-size: 14px;
  }
}

.activeStatus {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1F9741;
}