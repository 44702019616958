$snackbar-text-succes: #395143;
$snackbar-text-error: #2D3136;
$snackbar-box-succes: #1dd36ff3;
$snackbar-box-error: #d44846f0;
$snackbar-box-info: #f6d14bee;
$snackbar-text-info: #211f1a;

.snackbar {
  min-width: 250px;
  width: fit-content;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.success {
  background-color: $snackbar-box-succes;
  .message {
    color:$snackbar-text-succes
  }
  .symbol {
    svg {
      fill: $snackbar-text-succes
    }
  }
  .button {
    svg {
      fill: $snackbar-text-succes;
    }
  }
}

.error {
  background-color: $snackbar-box-error;
  .message {
    color:$snackbar-text-error;
  }
  .symbol {
    svg {
      fill: $snackbar-text-error;
    }
  }
  .button {
    svg {
      fill: $snackbar-text-error;
    }
  }
}

.info {
  background-color: $snackbar-box-info;
  .message {
    color:$snackbar-text-info;
  }
  .symbol {
    svg {
      fill: $snackbar-text-info;
    }
  }
  .button {
    svg {
      fill: $snackbar-text-info;
    }
  }
}

.message {
  font-weight: 500;
}

.symbol {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.button {
  background: none;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin-right: 5px;
  height: 30px;
  pointer-events:all;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: rgba(106, 105, 105, 0.278);
  }
}