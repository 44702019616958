@import '../../../styles/variablesNew.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  h4 {
    margin-bottom: 5px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .inputContainer {
      display: flex;
      flex-direction: row;
      flex-basis: 50%;
      margin-top: 12px;
    }

    .customCheckbox input[type="checkbox"] {
      display: none;
    }

    .customCheckbox input[type="radio"] {
      display: none;
    }
    
    .customCheckbox {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      flex-direction: row;
      min-height: 43px;
      position: relative;

      @media screen and (max-width: $tablet-width) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }

      &[itemType='checkbox'] .checkboxText::before {
        content: '';
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 8px;
        background-color: #FFFEFC;
        vertical-align: middle;
        transition: background-color 0.3s ease;
        border: 1px solid #ccc;
      }

      &[itemType='radio'] .checkboxText::before {
        content: '';
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #FFFEFC;
        vertical-align: middle;
        transition: all 0.3s ease;
        border: 1px solid #ccc;
      }

      
      // &[itemProp='error'] .checkboxText {
      //   margin-top: 12px;
      // }

      // &[itemProp='error']::before {
      //   margin-top: 10px;
      // }

      // &[itemProp='error'] {
      //   align-items: flex-start;
      // }
      
    }

    .checkboxText:hover::before {
      filter: brightness(0.96);
    }

    .customCheckbox input[type="checkbox"]:checked + .checkboxText::after {
      content: '';
      position: absolute;
      color: $primary;
      background-image: url('../../../public/images/newSVG/checked.svg');
      background-repeat: no-repeat;
      background-position: center center;
      width: 20px;
      height: 20px;
      left: 6px;
      animation: scale 0.5s ease;
    }

    .customCheckbox input[type="radio"]:checked + .checkboxText::after {
      content: '';
      position: absolute;
      color: $primary;
      background-image: url('../../../public/images/newSVG/checkedRadio.svg');
      background-repeat: no-repeat;
      background-position: center center;
      width: 20px;
      height: 20px;
      left: 5px;
      animation: scale 0.5s ease;
    }

    @keyframes scale {
      0% {
        transform: scale(0.6);
        opacity: 0.6;
      }
      20% {
        transform: scale(1.2);
        opacity: 1;
      }
      40% {
        transform: scale(1.1);
        opacity: 1;
      }
      60% {
        transform: scale(1.05);
        opacity: 1;
      }
      80% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    
    .checkboxText {
      font-size: 16px;
      color: #1C1C1C;
      font-family: 'Roboto Flex';
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
  }
  
  .inputOther {
    width: 550px;
    height: 40px;
    padding-left: 10px;
    border: 1px solid #b0b0b0;
  }
}

.afterSuggestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    margin-top: 30px;
  }
  
  p {
    max-width: 400px;
  }

}


