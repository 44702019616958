@import '../../styles/variablesNew.scss';

.footer {
  width: 100%;
  background: linear-gradient(135deg, #48494c 25%, #404a6d 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex: 0 1 auto;
  gap: 100px;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media screen and (max-width: $desktop-width) {
    padding: 40px;
    gap: 40px;
  }

  @media screen and (max-width: $tablet-width) {
    padding: 20px;
    padding-bottom: 100px;
    align-items: flex-start;
  }

  > ul {
    display: grid;
    margin-top: 40px !important;
    max-width: $desktop-width;
    grid-template-columns: 2fr 1fr 1fr 1fr 1.5fr 0.5fr;
    gap: 28px;
    width: 100%;

    @media screen and (max-width: $desktop-width) {
      grid-template-columns: repeat(5, auto);
      margin-top: auto !important;
    }

    @media screen and (max-width: $tablet-width) {
      grid-template-columns: 1fr;
      margin-top: auto !important;
    }

    .footerTitle {
      color: $secondary;
      margin-bottom: 28px;
    }

    .miniList {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: white;

      a:hover {
        text-decoration: underline;
      }

      .social {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }

    .contactList {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: white;
      margin-top: 16px;
      margin-bottom: 16px;

      a:hover {
        text-decoration: underline;
      }
    }

    .rightPattern {
      @media screen and (max-width: $desktop-width) {
        position: absolute;
        right: 0;
      }
    }
  }

  .bottomPattern {
    position: absolute;
    bottom: 0px;
    left: 20px;
    opacity: 0.5;

    @media screen and (max-width: $tablet-width) {
      opacity: 0.2;
    }
  }

  .bottomInfo {
    color: white;
    margin-bottom: 35px;
    max-width: $desktop-width;
    width: 100%;
    z-index: 22;

    a {
      text-decoration: underline;
    }

    @media screen and (max-width: $desktop-width) {
      margin-bottom: 0px;

      span > br {
        display: none;
      }
    }

    @media screen and (max-width: $tablet-width) {
      span > br {
        display: none;
      }
    }
  }

}
