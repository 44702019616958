@import './mixins.scss';
@import './variables.scss';

.mobileContainer {
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  gap: 15px;
  width: 100%;
  .header {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}

.item {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  position: relative;
  p {
    margin: 0;
  }
}

.itemOutdated {
  text-decoration: line-through;
  color: red;
}

.itemNew {
  display: block;
}

.amountItem {
  text-align: center;
}
