@import '../../styles/variablesNew.scss';

.container {
  border: 0;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: 'Noto sans';
  font-size: 16px;
  align-items: center;
  color: #262626B8;
  cursor: pointer;
}

.selectCityList {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 6px;

  .item {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  button {
    border: 0;
    background-color: inherit;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    color: $primary;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $primary;
    }
  }
}