.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}

.suggestContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-self: stretch;
  .name {
    flex-basis: 40%;
  }
  .emailPhone {
    flex-basis: 100%;
  }
  .suggestionTitle {
    flex-basis: 100%;
  }
  .suggestionText {
    flex-basis: 100%;
  }
}