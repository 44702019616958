@import '../../../styles/variables.scss';

.input {
  & input {
    padding-right: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.input_clear {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  height: 40px;
  padding:  0px 15px;
  cursor: pointer;
  opacity: 0.7;
}

.container {
  position: relative;
}

.list_container {
  position: absolute;
  background-color: #FFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 0px;
  z-index: 10;

  .loadBox {
    display: flex;
    justify-content: center;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    color: #ddd;
  }

  ul {
    padding: 5px 0px ;
    margin: 0;
  }

  li {
    list-style: none;
    min-height: 40px;
    padding: 10px;
    cursor: pointer;
    font-size: 0.9em;
    width: 100%;
  }
  
  li:hover {
    background-color: lighten($secondary, 20%);
  }
}

.loading {
  width: 20px;
  height: 20px; 
  border: 4px solid transparent;
  border-top-color: rgb(155, 155, 155);
  border-radius: 50%;
  animation: button-loading-spinner 1.2s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}