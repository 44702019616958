@import '../../../styles/variablesNew.scss';

.container {
  flex-grow: 1;
  padding-bottom: 5px;
}
.label {
  font-size: 16px;
  color: black;
  font-weight: 300;
  font-family: 'Roboto Flex';
  margin-bottom: 2px;
  line-height: 24px;
  display: inline-block;
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 2px;
}
