.container {
  max-width: 1100px;
  margin-top: 45px;
  position: relative;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.backLink {
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  .backButton {
    transform: rotate(180deg);
    margin-right: 26px;
    cursor: pointer;
  }
  @media (min-width: 500px) {
  }
}