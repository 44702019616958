@import 'styles/variablesNew.scss';

.filesDropContainer {
  background-color: #f8f7f5;
  border-radius: 8px;
  min-height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .filesDrop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #595A5D;
    width: 100%;
    justify-content: center;
  }
  
}

.filesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 15px;
  justify-content: center;
}

.fileItem {
  margin-bottom: 5px;
  background-color: #ebeef9;
  padding: 6px 12px;
  border-radius: 3px;
  
  button {
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
    color: #333;
  }
}

.fileInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;

  button {
    svg {
      fill: red;
    }
  }
}
.fileItemError {
  background-color: #f9d0cf;
}

.spin {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid #496ef0;
  border-bottom: 2px solid #496ef0;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin-right: 5px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  font-size: 0.9em;
  color: #ab1c1c;
  margin: 5px 0 5px 0;
}
