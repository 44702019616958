.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .checkbox {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}