@import './mixins.scss';
@import './variables.scss';

.skeleton {
  opacity: .7;
  animation: skeleton-loading 1.2s linear infinite alternate;
  width: 100%;
  height: 100%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}