@import './variablesNew.scss';



.layout {
  display: flex;
  justify-content: space-between;

  .content {
    flex-basis: 80%;
    min-height: 60vh;

    @media screen and (max-width: $desktop-width) {
      flex-basis: 100%;
    };
  }
  
  .list {
    flex-basis: 20%;
  }

  @media screen and (max-width: $desktop-width) {
    display: block;
    .list {
      .companyInfo {
        margin-bottom: 10px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  @media screen and (max-width: $tablet-width) {
    flex-basis: 0%;
    border-bottom: 1px solid #eee;
    margin-bottom: 1 * 10px;
  }
  li {
    margin-bottom: 1 * 10px;
    margin-right: 1.5 * 10px;

    a {
      display: inline-flex;
      flex-direction: row;
      color: #333;
      text-decoration: none;
      font-size: 1.1em;
      font-weight: 400;
      padding: 5px 10px;
      border-radius: 5px;
      &:hover {
        background-color: rgba(255, 227, 127, 0.5);
      }
    }
  }

  .active {
    a {
      color: #222;
      background-color: rgba(246, 209, 75, 0.5);
    }
  }

  .suggestion {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
    font-family: inherit;
    color: #1469B7;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    text-decoration: none;
    margin-top: 30px;
    @media screen and (max-width: $desktop-width) {
      margin-top: 0px;
      margin-left: 30px;
    }
    @media screen and (max-width: $tablet-width) {
      margin-left: 0px;
      margin-bottom: 30px;
    }
  }
}

.btnLink {
  width: 60px;
  text-align: center;
}