.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}