.popoverContainer {
  position: relative;
  display: inline-block;
  top: 3px;
  margin-left: 4px;
}

.popoverTrigger {
  cursor: help;
}

.popoverContentRight {
  position: absolute;
  background-color: #4d4d4d;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 4px;
  left: 35px;
  top: 10px;
  z-index: 100;
  min-width: 190px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  white-space: balance;

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 22;
    top: -8px;
    left: -8px;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #4d4d4d;
    transform: rotate(315deg);
  }
}

.popoverContentBottom {
  position: absolute;
  background-color: #4d4d4d;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 4px;
  left: -41px;
  top: 34px;
  z-index: 100;
  min-width: 100px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  white-space: balance;

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 22;
    top: -8px;
    left: 43px;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #4d4d4d;
    transform: rotate(135deg);
  }
}