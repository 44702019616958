@import '../../styles/variablesNew.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 5px;
  padding: 5px 0;
  font-size: 16px;

  @media screen and (max-width: $desktop-width) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: $tablet-width) {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  a {
    font-size: 16px;
    color: $primary;
    white-space: nowrap;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.separator {
  white-space: nowrap;
  display: inline-block;
  margin: 0 12px;
  color: #999999;
}

.noLinkItem {
  color: #555;
}