.container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  gap: 20px;
  min-height: 50vh;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.6fr;
  padding: 5px 25px ;
  width: 100%;
}

.ordersContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}



