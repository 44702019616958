@import '../../styles/variablesNew.scss';

.placeholder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  z-index: 10;

  @media screen and (max-width: $tablet-width) {
    padding-top: 170px;
    top: -70px;
  }
}

.container {
  position: relative;
  max-width: 636px;
  width: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  margin: 0 16px;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: flex-start;

  h2 {
    margin: 0;
    font-size: 22px;
    line-height: 28px
  }
}

.closeBtn {
  border: 0;
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s;
  
  &:hover {
    transform: scale(1.1);
  }
}
