@import './mixins.scss';
@import './variables.scss';

.container {
  max-width: 800px;
  column-gap: 20px;
  row-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  h4 {
    margin: 15px 0 10px 0;
  }
  @include media('<=#{$tablet-step}') {
    grid-template-columns: 1fr;
    row-gap: 10px;
  };
}

.title {
  grid-column-start: 1;
  grid-column-end: 4;
  @include media('<=#{$tablet-step}') {
    grid-column-start: 1;
    grid-column-end: 1;
  };
}

.code {
  grid-column-start: 4;
  grid-column-end: 5;
  @include media('<=#{$tablet-step}') {
    grid-column-start: 1;
    grid-column-end: 1;
  };
}

.description {
  grid-column-start: 1;
  grid-column-end: 5;
  @include media('<=#{$tablet-step}') {
    grid-column-start: 1;
    grid-column-end: 1;
  };
}

.buttonContainer {
  display: flex;
  padding: 30px 15px 10px 15px;
  justify-content: flex-end;
  @include media('<=#{$tablet-step}') {
    justify-content: center;
  };
} 

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  gap: 40px;
  @include media('>=#{$tablet-step}') {
    flex-direction: row;
  };
}

.lightGallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  min-width: 150px;
  width: 100%;
}

.imageGallery {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}