.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.box {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  .item {
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.header {
  flex-basis: 50%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.orderBtn {
  align-self: center;
}