@import './mixins.scss';
@import './variables.scss';

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  align-items: center;
  padding: 5px 25px ;
  min-height: 60px;
}

.ordersContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.order {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.active {
  .item {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .activeStatus {
    color: #1F9741;
  }
}

.orderBtn {
  justify-self: center;
  
  div {
    height: 10px;
  }
}

.item {
  padding: 10px;
}

.header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}