@import './mixins.scss';
@import './variables.scss';

.backLink {
  margin-bottom: 45px;
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  .backButton {
    transform: rotate(180deg);
    margin-right: 26px;
    cursor: pointer;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.confirmModal {
  min-width: 300px;
  min-height: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  .confirmationButtonsBox {
    display: flex;
    justify-content: space-between;
  }
}

.infoTable {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
  row-gap: 25px;
  column-gap: 15px;
  @include media('<#{$mobile-step}') {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}

.isAllCheckedBill {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.mobileContainer {
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  gap: 15px;
  width: 100%;
}
.productsTable {
  display: grid;
  grid-template-columns: 0.5fr 5fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 10px;
  row-gap: 15px;
  .header {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  .amountItem {
    justify-self: center;
  }
  @include media('<=#{$mobile-step}') {
    & > .item {
      display: none;
    }
    display: flex;
    flex-direction: column;
  }
}
.allChecked {
  grid-template-columns: 0.5fr 5fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.addressItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column-start: 1;
  grid-column-end: 2;
}

.header {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.billWaitHelp {
  min-width: 250px;
  font-size: 14px;
}

.bottomActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}