@import './mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.companyCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  @include media('<=#{$mobile-step}') {
    flex-direction: column;
  }
}

.companyInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.openBtn {
  justify-self: center;
  div {
    height: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.companyIcon {
  margin-right: 8px;
}
