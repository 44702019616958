@import '../../../styles/variables.scss';

.confirmModal {
  min-width: 450px;
  min-height: 200px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 15px;
  justify-content: space-around;
  .confirmationButtonsBox {
    display: flex;
    justify-content: space-between;
  }
  textarea {
    resize: none;
    height: 150px;
  }
}

.error {
  color: $error;
  font-size: 0.9em;
  margin-top: 2px;
}