.container {
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  position: fixed;
  gap: 10px;
  height: auto;
  width: auto;
  bottom: 2%;
  flex-direction: column-reverse;
  left: 2%;
}