@import '../../styles/variablesNew.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
  background-color: #f8fbfe;
  gap: 40px;
  flex: 1 1;
  min-height: 1000px;

  @media screen and (max-width: $desktop-width) {
    padding: 20px 0;
    gap: 20px;
  }
  
  a {
    text-decoration: none;
  }

  & > div {
    max-width: $desktop-width;
    align-self: center;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 44px;
    background-color: white;
    gap: 48px;
    border-radius: 16px;
    box-shadow: 2px 0px 10px 0px #4C4B4B0D;

    @media screen and (max-width: $tablet-width) {
      padding: 20px;
    }
    
    h1 {
      font-size: 32px;
      line-height: 44px;
      font-weight: 500;
      color: #262626;
    }
  }
}