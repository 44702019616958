@import '../../styles/variablesNew.scss';


.catalog {
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: center;
  max-width: $desktop-width;
  gap: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  a {
    text-decoration: none;
    color: inherit;
  }
  
  @media screen and (max-width: $desktop-width) {
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  @media screen and (max-width: $tablet-width) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow: auto;
  }

  li {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  li a {
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border-radius: 16px;
    padding: 16px;
    gap: 23px;
    justify-content: space-between;
    transition: all 0.3s ease;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: $primary;
      p {
        color: white;
      }

      span {
        filter: invert(1);
      }
    }
    
    p {
      font-size: 20px;
      color: #2C2C2CD6;
      font-family: 'Roboto Flex';
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: $tablet-width) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: $desktop-width) and (min-width: $tablet-width) {
      flex-direction: row;
      justify-content: flex-start;

      svg {
        flex: 1 4 100%;
      }
      p {
        flex-basis: 100%;
      }
    }

    @media screen and (max-width: $tablet-width) {
      padding: 12px 16px 12px 16px;
    }
  }
}

.catalogItemIcon {
  display: inline-block;
  min-width: 56px;
  min-height: 56px;
  background-size: contain;
  background-repeat: no-repeat;
}


.catalogNotIndex {
  @media screen and (max-width: $tablet-width) {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  li a {
    @media screen and (max-width: $tablet-width) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    p {
      @media screen and (max-width: $tablet-width) {
        hyphens: auto;
      }
    }
  }
  
}