.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.billingContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-self: stretch;
  .bik {
    flex-basis: 40%;
  }
  .account {
    flex-basis: calc(60% - 10px);
  }
  .bankName {
    flex-basis: 100%;
  }
  .isActiveCheckBox {
    flex-basis: 10%;
  }
}