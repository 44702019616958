@import '../../../styles/variables.scss';

.inputBox{
  input {
    cursor: pointer;
    padding-right: 40px;
  }
}


.inputClear {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  height: 40px;
  padding:  0px 15px;
  cursor: pointer;
  opacity: 0.7;
}

.container {
  position: relative;
}

.listContainer {
  position: absolute;
  background-color: #FFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 0px;
  z-index: 100;

  .loadBox {
    display: flex;
    justify-content: center;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    color: #ddd;
  }

  ul {
    padding: 5px 0px ;
    margin: 0;
  }

  li {
    list-style: none;
    min-height: 40px;
    padding: 10px;
    cursor: pointer;
    font-size: 0.9em;
    width: 100%;
  }
  
  li:hover {
    background-color: lighten($secondary, 20%);
  }
}

