@import './variables.scss';
@import './mixins.scss';

.billingCard {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  @include media('<=#{$tablet-step}') {
    flex-direction: column;
  }
}

.billingInfo {
  flex-basis: 70%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.billingStatus {
  flex-basis: 20%;
  .active {
    color: $active;
    font-weight: 600;
  }
}

.openBtn {
  flex-basis: 10%;
  justify-self: center;
  div {
    height: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}